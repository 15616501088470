import { useAuthStore } from "~/store/auth";

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const { $toast } = useNuxtApp();
  if (process.client) {
    if (!authStore.company?.subscribed) {
      $toast.error("Vous devez avoir un abonnement actif.");
      return navigateTo("/dashboard");
    }
  }
});
